import { getNodeChildren } from '../dom-helpers';
import { concatStyleStrings } from '../styles/styles';
import { getEffectiveAttribute } from '../styles/effective-attributes';
import { applyTransform } from '../transformation';
import { appendSketchElement } from '../utils';
export function drawText(context, text, svgTransform) {
    const container = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    container.setAttribute('class', 'text-container');
    applyTransform(context, svgTransform, container);
    const textClone = text.cloneNode(true);
    if (textClone.transform.baseVal.numberOfItems > 0) {
        // remove transformation, since it is transformed globally by its parent container
        textClone.transform.baseVal.clear();
    }
    const cssFont = getCssFont(context, text, true);
    textClone.setAttribute('style', concatStyleStrings(textClone.getAttribute('style'), cssFont));
    copyTextStyleAttributes(context, text, textClone);
    // apply styling to any tspan
    if (textClone.childElementCount > 0) {
        const children = getNodeChildren(textClone);
        const origChildren = getNodeChildren(text);
        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (child instanceof SVGTSpanElement) {
                copyTextStyleAttributes(context, origChildren[i], child);
            }
        }
    }
    container.appendChild(textClone);
    appendSketchElement(context, text, container);
}
/**
 * @param asStyleString Formats the return value as inline style string
 */
function getCssFont(context, text, asStyleString = false) {
    let cssFont = '';
    const fontStyle = getEffectiveAttribute(context, text, 'font-style', context.useElementContext);
    if (fontStyle) {
        cssFont += asStyleString ? `font-style: ${fontStyle};` : fontStyle;
    }
    const fontWeight = getEffectiveAttribute(context, text, 'font-weight', context.useElementContext);
    if (fontWeight) {
        cssFont += asStyleString ? `font-weight: ${fontWeight};` : ` ${fontWeight}`;
    }
    const fontSize = getEffectiveAttribute(context, text, 'font-size', context.useElementContext);
    if (fontSize) {
        cssFont += asStyleString ? `font-size: ${fontSize};` : ` ${fontSize}`;
    }
    if (context.fontFamily) {
        cssFont += asStyleString ? `font-family: ${context.fontFamily};` : ` ${context.fontFamily}`;
    }
    else {
        const fontFamily = getEffectiveAttribute(context, text, 'font-family', context.useElementContext);
        if (fontFamily) {
            cssFont += asStyleString ? `font-family: ${fontFamily};` : ` ${fontFamily}`;
        }
    }
    cssFont = cssFont.trim();
    return cssFont;
}
function copyTextStyleAttributes(context, srcElement, tgtElement) {
    const stroke = getEffectiveAttribute(context, srcElement, 'stroke');
    const strokeWidth = stroke ? getEffectiveAttribute(context, srcElement, 'stroke-width') : null;
    const fill = getEffectiveAttribute(context, srcElement, 'fill');
    const dominantBaseline = getEffectiveAttribute(context, srcElement, 'dominant-baseline');
    const textAnchor = getEffectiveAttribute(context, srcElement, 'text-anchor', context.useElementContext);
    if (stroke) {
        tgtElement.setAttribute('stroke', stroke);
    }
    if (strokeWidth) {
        tgtElement.setAttribute('stroke-width', strokeWidth);
    }
    if (fill) {
        tgtElement.setAttribute('fill', fill);
    }
    if (textAnchor) {
        tgtElement.setAttribute('text-anchor', textAnchor);
    }
    if (dominantBaseline) {
        tgtElement.setAttribute('dominant-baseline', dominantBaseline);
    }
}
