import rough from 'roughjs/bin/rough';
import { OutputType } from './OutputType';
import { processRoot } from './processor';
import { createPencilFilter } from './styles/textures';
import { getDefsElement } from './utils';
/**
 * Svg2Roughjs parses an SVG and converts it to a hand-drawn sketch.
 */
export class Svg2Roughjs {
    /**
     * Creates a new instance of Svg2roughjs.
     * @param target Either a selector for the container to which a canvas should be added
     * or an `HTMLCanvasElement` or `SVGSVGElement` that should be used as output target.
     * @param outputType Whether the output should be an SVG or drawn to an HTML canvas.
     * Defaults to SVG or CANVAS depending if the given target is of type `HTMLCanvasElement` or `SVGSVGElement`,
     * otherwise it defaults to SVG.
     * @param roughConfig Config object that is passed to Rough.js and considered during
     * rendering of the `SVGElement`s.
     */
    constructor(target, outputType = OutputType.SVG, roughConfig = {}) {
        /**
         * Optional solid background color with which the canvas should be initialized.
         * It is drawn on a transparent canvas by default.
         */
        this.backgroundColor = null;
        /**
         * Set a font-family for the rendering of text elements.
         * If set to `null`, then the font-family of the SVGTextElement is used.
         * By default, 'Comic Sans MS, cursive' is used.
         */
        this.fontFamily = 'Comic Sans MS, cursive';
        /**
         * Whether to randomize Rough.js' fillWeight, hachureAngle and hachureGap.
         * Also randomizes the disableMultiStroke option of Rough.js.
         * By default true.
         */
        this.randomize = true;
        /**
         * Whether pattern elements should be sketched or just copied to the output.
         * For smaller pattern base sizes, it's often beneficial to just copy it over
         * as the sketch will be too smalle to actually look sketched at all.
         */
        this.sketchPatterns = true;
        /**
         * Whether to apply a pencil filter.
         */
        this.pencilFilter = false;
        this.width = 0;
        this.height = 0;
        this.$roughConfig = {};
        this.idElements = {};
        this.lastResult = null;
        if (!target) {
            throw new Error('No target provided');
        }
        const targetElement = typeof target === 'string' ? document.querySelector(target) : target;
        if (!targetElement) {
            throw new Error('Could not find target in document');
        }
        this.roughConfig = roughConfig;
        this.outputElement = targetElement;
        if (targetElement instanceof HTMLCanvasElement) {
            this.$outputType = OutputType.CANVAS;
        }
        else if (targetElement instanceof SVGSVGElement) {
            this.$outputType = OutputType.SVG;
        }
        else {
            this.$outputType = outputType;
        }
    }
    /**
     * Set the SVG that should be sketched.
     */
    set svg(svg) {
        if (this.$svg !== svg) {
            this.$svg = svg;
            const precision = this.roughConfig.fixedDecimalPlaceDigits;
            this.width = parseFloat(this.coerceSize(svg, 'width', 300).toFixed(precision));
            this.height = parseFloat(this.coerceSize(svg, 'height', 150).toFixed(precision));
            // pre-process defs for subsequent references
            this.collectElementsWithID();
        }
    }
    /**
     * Returns the SVG that should be sketched.
     */
    get svg() {
        return this.$svg;
    }
    /**
     * Sets the output format of the sketch.
     *
     * Applies only to instances that have been created with a
     * container as output element instead of an actual SVG or canvas
     * element.
     *
     * Throws when the given mode does not match the output element
     * with which this instance was created.
     */
    set outputType(type) {
        if (this.$outputType === type) {
            return;
        }
        const incompatible = (type === OutputType.CANVAS && this.outputElement instanceof SVGSVGElement) ||
            (type === OutputType.SVG && this.outputElement instanceof HTMLCanvasElement);
        if (incompatible) {
            throw new Error(`Output format ${type} incompatible with given output element ${this.outputElement.tagName}`);
        }
        this.$outputType = type;
    }
    /**
     * Returns the currently configured output type.
     */
    get outputType() {
        return this.$outputType;
    }
    /**
     * Sets the config object that is passed to Rough.js and considered
     * during rendering of the `SVGElement`s.
     *
     * Sets `fixedDecimalPlaceDigits` to `3` if not specified otherwise.
     */
    set roughConfig(config) {
        if (typeof config.fixedDecimalPlaceDigits === 'undefined') {
            config.fixedDecimalPlaceDigits = 3;
        }
        this.$roughConfig = config;
    }
    /**
     * Returns the currently configured rendering configuration.
     */
    get roughConfig() {
        return this.$roughConfig;
    }
    /**
     * Triggers an entire redraw of the SVG which
     * processes the input element anew.
     * @returns A promise that resolved to the sketched output element or null if no {@link svg} is set.
     */
    sketch() {
        var _a, _b;
        if (!this.svg) {
            return Promise.resolve(null);
        }
        const sketchContainer = this.prepareRenderContainer();
        const renderContext = this.createRenderContext(sketchContainer);
        // prepare filter effects
        if (this.pencilFilter) {
            const defs = getDefsElement(renderContext);
            defs.appendChild(createPencilFilter());
        }
        // sketchify the SVG
        renderContext.processElement(renderContext, this.svg, null, this.width, this.height);
        if (this.outputElement instanceof SVGSVGElement) {
            // sketch already in the outputElement
            return Promise.resolve(this.outputElement);
        }
        else if (this.outputElement instanceof HTMLCanvasElement) {
            return this.drawToCanvas(renderContext, this.outputElement);
        }
        // remove the previous attached result
        (_b = (_a = this.lastResult) === null || _a === void 0 ? void 0 : _a.parentNode) === null || _b === void 0 ? void 0 : _b.removeChild(this.lastResult);
        // assume that the given output element is a container, thus append the sketch to it
        if (this.outputType === OutputType.SVG) {
            const svgSketch = renderContext.svgSketch;
            this.outputElement.appendChild(svgSketch);
            this.lastResult = svgSketch;
            return Promise.resolve(svgSketch);
        }
        else {
            // canvas output type
            const canvas = document.createElement('canvas');
            this.outputElement.appendChild(canvas);
            this.lastResult = canvas;
            return this.drawToCanvas(renderContext, canvas);
        }
    }
    /**
     * Creates a new context which contains the current state of the
     * Svg2Roughs instance for rendering.
     */
    createRenderContext(sketchContainer) {
        if (!this.svg) {
            throw new Error('No source SVG set yet.');
        }
        return {
            rc: rough.svg(sketchContainer, { options: this.roughConfig }),
            roughConfig: this.roughConfig,
            fontFamily: this.fontFamily,
            pencilFilter: this.pencilFilter,
            randomize: this.randomize,
            sketchPatterns: this.sketchPatterns,
            idElements: this.idElements,
            sourceSvg: this.svg,
            svgSketch: sketchContainer,
            styleSheets: Array.from(this.svg.querySelectorAll('style'))
                .map(s => s.sheet)
                .filter(s => s !== null),
            processElement: processRoot
        };
    }
    /**
     * Helper method to draw the sketched SVG to a HTMLCanvasElement.
     */
    drawToCanvas(renderContext, canvas) {
        canvas.width = this.width;
        canvas.height = this.height;
        const canvasCtx = canvas.getContext('2d');
        canvasCtx.clearRect(0, 0, this.width, this.height);
        return new Promise(resolve => {
            const svgString = new XMLSerializer().serializeToString(renderContext.svgSketch);
            const img = new Image();
            img.onload = function () {
                canvasCtx.drawImage(this, 0, 0);
                resolve(canvas);
            };
            img.src = `data:image/svg+xml;charset=utf8,${encodeURIComponent(svgString)}`;
        });
    }
    /**
     * Prepares the given SVG element depending on the set properties.
     */
    prepareRenderContainer() {
        let svgElement;
        if (this.outputElement instanceof SVGSVGElement) {
            // just use the user given outputElement directly as sketch-container
            svgElement = this.outputElement;
        }
        else {
            // we need a separate svgElement as output element
            svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        }
        // make sure it has all the proper namespaces
        svgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        svgElement.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns:xlink', 'http://www.w3.org/1999/xlink');
        // clear SVG element
        while (svgElement.firstChild) {
            svgElement.removeChild(svgElement.firstChild);
        }
        // set size
        svgElement.setAttribute('width', this.width.toString());
        svgElement.setAttribute('height', this.height.toString());
        // apply backgroundColor
        let backgroundElement;
        if (this.backgroundColor) {
            backgroundElement = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
            backgroundElement.width.baseVal.value = this.width;
            backgroundElement.height.baseVal.value = this.height;
            backgroundElement.setAttribute('fill', this.backgroundColor);
            svgElement.appendChild(backgroundElement);
        }
        // use round linecap to emphasize a ballpoint pen like drawing
        svgElement.setAttribute('stroke-linecap', 'round');
        return svgElement;
    }
    /**
     * Stores elements with IDs for later use.
     */
    collectElementsWithID() {
        this.idElements = {};
        const elementsWithID = Array.prototype.slice.apply(this.svg.querySelectorAll('*[id]'));
        for (const elt of elementsWithID) {
            const id = elt.getAttribute('id');
            if (id) {
                this.idElements[id] = elt;
            }
        }
    }
    /**
     * Helper to handle percentage values for width / height of the input SVG.
     */
    coerceSize(svg, property, fallback) {
        let size = fallback;
        const hasViewbox = svg.hasAttribute('viewBox');
        if (svg.hasAttribute(property)) {
            // percentage sizes for the root SVG are unclear, thus use viewBox if available
            if (svg[property].baseVal.unitType === SVGLength.SVG_LENGTHTYPE_PERCENTAGE && hasViewbox) {
                size = svg.viewBox.baseVal[property];
            }
            else {
                size = svg[property].baseVal.value;
            }
        }
        else if (hasViewbox) {
            size = svg.viewBox.baseVal[property];
        }
        return size;
    }
}
