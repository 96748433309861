import { appendPatternPaint } from '../styles/pattern';
import { parseStyleConfig } from '../styles/styles';
import { applyMatrix } from '../transformation';
import { appendSketchElement, getPointsArray } from '../utils';
import { drawMarkers } from './marker';
export function drawPolyline(context, polyline, svgTransform) {
    const points = getPointsArray(polyline);
    const transformed = points.map(p => {
        const pt = applyMatrix(p, svgTransform);
        return [pt.x, pt.y];
    });
    const style = parseStyleConfig(context, polyline, svgTransform);
    appendPatternPaint(context, polyline, () => {
        const proxy = document.createElementNS('http://www.w3.org/2000/svg', 'polyline');
        proxy.setAttribute('points', transformed.join(' '));
        return proxy;
    });
    if (style.fill && style.fill !== 'none') {
        const fillStyle = Object.assign(Object.assign({}, style), { stroke: 'none' });
        appendSketchElement(context, polyline, context.rc.polygon(transformed, fillStyle));
    }
    appendSketchElement(context, polyline, context.rc.linearPath(transformed, style));
    drawMarkers(context, polyline, points, svgTransform);
}
